<div class="d-flex text-center">
    <div class="container d-flex flex-column">
        <div class="row">
            <div class="col-12 col-md-8 offset-md-2 mt-2">
                <p class="my-2 display-4 font-weight-light random-title"><a routerLink="/dashboard"
                        routerLinkActive="active">randomizer.ca</a></p>

                <div class="d-flex flex-row justify-content-between mb-4">

                    <button type="button" class="btn btn-dark btn-random-third" routerLink="/strings"
                        routerLinkActive="active">strings</button>
                    <button type="button" class="btn btn-dark btn-random-third" routerLink="/numbers"
                        routerLinkActive="active">numbers</button>
                    <button type="button" class="btn btn-dark btn-random-third" routerLink="/games"
                        routerLinkActive="active">games</button>

                </div>

                <hr class="hr-random">

            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-8 offset-md-2 mt-2">

                <router-outlet></router-outlet>

            </div>
        </div>

    </div>
</div>