<p class="h3 font-weight-light">games</p>

<hr class="hr-random mb-4">

<div class="d-flex flex-column align-items-center">

    <button type="button" class="btn btn-dark btn-random-full" routerLink="/games/dice-roller"
        routerLinkActive="active">dice roller</button>
    <p>(roll one or multiple dice)</p>


</div>