<p class="h3 font-weight-light">strings</p>

<hr class="hr-random mb-4">

<div class="d-flex flex-column align-items-center">

    <button type="button" class="btn btn-dark btn btn-random-full" routerLink="/strings/list-randomizer"
        routerLinkActive="active">list randomizer</button>
    <p>(sort/randomize a list of items)</p>
    <button type="button" class="btn btn-dark btn btn-random-full" routerLink="/strings/password-generator"
        routerLinkActive="active">password generator</button>
    <p>(generate a strong password)</p>
    <button type="button" class="btn btn-dark btn btn-random-full" routerLink="/strings/bob-ross-ipsum"
        routerLinkActive="active">bob ross ipsum</button>
    <p>(do you want to get crazy?)</p>
    <button type="button" class="btn btn-dark btn btn-random-full" routerLink="/strings/dad-jokes"
        routerLinkActive="active">dad jokes</button>
    <p>(dad?)</p>


</div>