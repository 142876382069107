<p class="h3 font-weight-light">numbers</p>

<hr class="hr-random mb-4">

<div class="d-flex flex-column align-items-center">

    <button type="button" class="btn btn-dark btn-random-full" routerLink="/numbers/telephone-number"
        routerLinkActive="active">telephone number</button>
    <p>(generate a fake telephone number)</p>
    <button type="button" class="btn btn-dark btn-random-full" routerLink="/numbers/integer-generator"
        routerLinkActive="active">integer generator</button>
    <p>(generate an integer within a range)</p>


</div>