<p class="h3 font-weight-light">integer generator</p>

<hr class="hr-random mb-4">


<div class="d-flex flex-row flex-wrap justify-content-center align-items-between">

    <div class="input-group int-input mb-2">
        <div class="input-group-prepend">
            <span class="input-group-text disable-select">min</span>
        </div>
        <input [(ngModel)]="minVal" type="text" class="form-control" aria-label="minimum"
            [ngClass]="{'is-invalid': minError || rangeError || (!minVal && clicked) }"
            (change)="checkMin(); checkRange();" placeholder="enter the minimum value" maxlength=14>
    </div>

    <div class="input-group int-input mb-2">
        <div class="input-group-prepend">
            <span class="input-group-text disable-select">max</span>
        </div>
        <input [(ngModel)]="maxVal" type="text" class="form-control" aria-label="maximum"
            [ngClass]="{'is-invalid': maxError || rangeError || (!maxVal && clicked) }"
            (change)="checkMax(); checkRange();" placeholder="enter the maximum value" maxlength=14>
    </div>
</div>


<div class="d-flex flex-column align-items-center mt-3">
    <div class="random-output-box d-flex flex-column justify-content-center" (click)="copyString()"
        [ngClass]="{'random-unvisited': !copied, 'random-visited': copied, 'no-hover': !valid}">
        <p *ngIf="valid" class="h3 disable-select text-code">
            {{randInt}}
        </p>
        <p *ngIf="valid" class="justify-content-end text-muted disable-select">
            {{copied ? 'copied to clipboard!' : 'click to copy'}}
        </p>
    </div>
</div>

<div class="d-flex flex-row justify-content-center">
    <button type="button" class="btn btn-dark btn-random-half mx-2 mt-4" (click)="getInt()">
        generate integer</button>
</div>
<p class="mt-1 random-error-msg" *ngIf="clicked && (minError || maxError || rangeError)">invalid min/max
    values</p>