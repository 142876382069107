<p class="h3 font-weight-light">list randomizer</p>

<hr class="hr-random mb-4">
<div class="d-flex flex-wrap align-items-center justify-content-between">

    <!-- TEXT AREA -->
    <textarea #textInput class="random-list-textarea mb-3 d-flex w-100 h-100" [(ngModel)]="currentInput" rows="10"
        placeholder="enter one item per line"></textarea>

    <!-- CONTROLS -->


    <button type="button" class="btn btn-dark btn-random-half my-1" (click)="sortList(currentInput)">sort</button>
    <button type="button" class="btn btn-dark btn-random-half my-1"
        (click)="randomizeList(currentInput)">randomize</button>
    <button type="button" class="btn btn-dark btn-random-half my-1" (click)="removeDupes(currentInput)">remove
        dupes</button>
    <button type="button" class="btn btn-dark btn-random-half my-1" (click)="clearList()">clear</button>

</div>



<!-- <p>randomized: {{randomizedItems}}</p>
<p>current input: {{currentInput}}</p> -->